@font-face {
    font-family: 'Questa Sans';
    src: url('fonts/QuestaSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Questa Sans';
    src: url('fonts/Questa Sans Light Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'National 2 Compressed';
    src: url('fonts/National2Compressed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
